import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/react-fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import "./home.css"
import {About} from '../About/about';
import LogoAutoPlay from '../Logocarousel/logoCarousel';
import VerticalCarousel from '../Testimonial/testimonial'
import WorkCarousel from '../Work/work';
import Footer from '../Footer/footer';
import Button from '../Button/button';
import Experience from '../Experience/experience';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

 

Aos.init();

function Home(){
  
  // google analytics useEffect
  useEffect(()=>{
       ReactGA.pageview(window.location.pathname);
  },[])


    return(
      <> 
        <div className='landing-home'>
        <div className='container-fluid-landinghome'>
         <div className='row'>
          <div className='col col-lg-6 col-md-6 col-sm-12 '>
             <video className="landing-img" muted loop autoPlay data-aos="fade-right"  data-aos-delay="200" data-aos-duration="1000">
              <source src='/videos/MDM Website Landing Animation (1).mp4'></source>
             </video>

             <div className='row'>
           
              </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
          <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" className='heading-col-home'>Welcome to Michael D Media Your Partner In <span className='success-bg-img'>Success</span></p>
       
         
          </div>
         </div>

         <div className='row'>
           <div className='col-lg-6  col-md-6 col-sm-12  col-insta-link' data-aos="fade-right"  data-aos-delay="200" data-aos-duration="1500">
           <span className='span-instaincon-homelanding'>
             <FontAwesomeIcon icon={faInstagram} beat style={{color: "#ffffff",}} />
              <a href='https://www.instagram.com/michael_d_media/' target="_blank"><p  style={{color:"#fff", display:"inline-block", marginLeft:"10px"}}>Michael_d_media</p></a>
              </span>
           </div>
           <div className='col col-lg-6  col-md-6  col-sm-12  ' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
           <div className='landing-buttons-container'>
           <Link  to={"/projects"}><Button buttonName="See Our Work" className="button1" iconClassName="playicon" icon={faCirclePlay} iconColor="#002042" /></Link>
           <Link  to={"/Booking"}><Button buttonName="Book a Meeting" className="button2" iconClassName="meetingicon" icon={faCalendarDays} iconColor="#ffff" /></Link>
          
          </div>
           </div>
         </div>
         
        </div>
        </div>

        {/* about */}
        <About/>

        {/* Experience */}
        <Experience/>

        {/* logo autoplay */}
        <LogoAutoPlay/>

         {/* featured projects */}
         <div className='featured-projects-container'>
  <h1>Michael D Media Evolution</h1>
   <video muted loop autoPlay controls className='mdm-evolutionVideo' data-aos="zoom-in" delay="100"  duration="900">
      <source src='/videos/MDM 3-3 - Evolution Horizontal_1.mp4'></source>
    </video>
  </div>
        {/* testimonialll */}
        {/* <VerticalCarousel/> */}
         
        {/* work section carousel */}
        <WorkCarousel/>

        {/* footer section */}
        <Footer/>
        </>

    );
}

export default Home;