import './Tidycal.css'
import Footer from '../Footer/footer';
const TidyCalEmbed = () => {
   
    return (
      <div className='outer-tidical'> 
      <div class="tidycal-container">
     <iframe src="https://tidycal.com/michaeldimasciomedia/discovery-call"></iframe>
    </div>
     <Footer/>
    </div>
    );
  };

 
export default TidyCalEmbed;
