import Footer from "../Footer/footer";
import { FirstSection,  FourthSection } from "./commonProjectPages";
import { Client4 } from "../Data/clientsData";

function JDdesign(){
    return(
        <>
        <div className='container-fluid-eachProject'>
          <FirstSection 
            projectTitle={Client4.projectTitle}
            year={Client4.year}
            projectType={Client4.projectType}
            clientCompanyName={Client4.companyName}
             projectVideo1={Client4.video1}
            //  projectVideoReel1={Client4.reel1}
            viemoIframe={Client4.vimeo}
             descriptionFirstSection={Client4.disc1}
          />

        <FourthSection
        clientPhoto={Client4.clientPhoto}
        clientName={Client4.clientName}
        clientCompany ={Client4.companyName}
        clientTextReview={Client4.reviewText}
        clientVideoReview={Client4.reviewVideo}
     />
        
    </div>

<Footer/>
</>
    );
}

export default JDdesign;