import React,{useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Footer from '../Footer/footer'
import TypewriterComponent from 'typewriter-effect'; 
import './commonProjects.css'
import Vimeo from '@vimeo/player'; 
import ScrollTrigger from 'react-scroll-trigger'; 

function FirstSection(props){
  useEffect(() => {
    const vimeoPlayer = new Vimeo(
      document.querySelector(".vimeo-container iframe")
    );

    // Mute the video when the component mounts
    vimeoPlayer.setVolume(0);

    // You can control the sound further by interacting with the vimeoPlayer object
  }, []);
  const vimeoEmbedCode = `<iframe src="${props.viemoIframe}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Silva 4.1"></iframe>`;
  const SRC = props.projectVideo1;
  return(
      <>
        <h1 className='eachproject-mainTitle'>{props.projectTitle}</h1>
        <div className='row d-flex justify-content-center project-details'>
            <div className='col details'><p>YEAR</p><h6>{props.year}</h6></div>
            <div className='col details'><p>PROJECT TYPE</p><h6>{props.projectType}</h6></div>
            <div className='col details'><p>CLIENT</p><h6>{props.clientCompanyName}</h6></div>
          </div>


          <div className='first-section-eachproject'>
          <div className='row'>
            <div className='col-lg-7 col-md-7 col-sm-12  '>
            {SRC.endsWith('mp4') ?  
            <video  autoPlay loop muted className='image1-eachproject' >
                    <source src={SRC} type='video/mp4'></source>
                </video>
                :
                <img src={SRC} className='image1-eachproject'></img>
                }
               
            </div>
            
             <div className='col-lg-5 col-md-5 col-sm-12  '> 
             
          <div className="vimeo-container"> 
             <div  className='inner-vimeo-reel'
             style={{borderRadius:"15px"}}
             dangerouslySetInnerHTML={{ __html: vimeoEmbedCode }}
             />
             <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        </div>

            </div>
            
            <p className='first-p-eachproject' data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">{props.descriptionFirstSection}</p>
            </div>
      </>
    );
}


function SecondSection(props){
   return(
    <div className='row'>
            <div className='col-6' data-aos="flip-right"  data-aos-delay="200" data-aos-duration="1000">
             <img className='image4-eachproject' src={props.secondSectionImag1}></img>
             <p className='before-p'>{props.secondSectionDisc1}</p>
              </div>
            <div className='col-6' data-aos="flip-left"  data-aos-delay="200" data-aos-duration="1000"> 
            <img className='image5-eachproject' src={props.secondSectionImag2}></img>
            <p className='after-p'>{props.secondSectionDisc2}</p>
            </div>
          </div>
   );
}


function ThirdSection(props){
  
  const [typeOn, setTypeOn] = useState(false);

    return(
      <ScrollTrigger onEnter={()=> setTypeOn(true)} onExit={()=>setTypeOn(false)}> 
        <div className='container-thirdSection'>
     <div className='row'>
             <div className='col-lg-6 col-md-6 col-sm-12'>
             <video className='mobileVideo-projectsPages' autoPlay loop muted  data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                <source src={props.mobileVideo1}></source>
             </video>
              </div>
             <div className='col-lg-6 col-md-6 col-sm-12 d-flex'> 
             <div className='div-right-mobile'>
             <h1>{props.videoTitle}</h1>
             {typeOn &&
             <p>
             <TypewriterComponent
           options={{
           autoStart: true,
           delay: 30,
           pauseFor: 300000,
           strings:
           [props.thirdDisc],
        }}/>
        </p>
             }
             <div>
             <a href={props.btn1Link} target='_blank'><button className='btn1-mobile-right'>{props.button1Name}</button></a>
             <Link to={props.btn2Link}><button className='btn2-mobile-right'>{props.button2Name}</button></Link>
             </div>
             </div>
             </div>
           </div>
    </div>
    </ScrollTrigger>
    );
 }


 function FourthSection(props){
    return(
        <div className='container-eachproject-testimonial-section'>
        <div className='row d-flex align-items-center'>
        <div className='col-lg-6 col-md-6 col-sm-12 order-lg-1 order-md-1 order-2 col-details ' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
        <div className='eachproject-testimonial-img-details-container'>
        <img src= {props.clientPhoto}></img>
         <div className='wrap-detais'> 
         <p>{props.clientName}</p>
         <h6>{props.clientCompany}</h6>
         </div>
     </div>
         
         <div className='eachproject-testimonial-p-start-container'> 
         <p>{props.clientTextReview}</p>
       <img src='/images/Group 33.png'></img>
     </div>
        </div>

        <div className='col-lg-6 col-md-6 col-sm-12 order-lg-2 order-md-2 order-1 ' data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000">
        <video className='videoReview-eachproject' controls>
          <source src={props.clientVideoReview}></source>
        </video>
        </div>

        </div>
        </div>
    );
 }


 export {FirstSection, SecondSection, ThirdSection, FourthSection};

 