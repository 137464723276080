import Footer from "../Footer/footer";
import { FirstSection, SecondSection,ThirdSection, FourthSection } from "./commonProjectPages";
import { Client3} from "../Data/clientsData";

function TscClean(){
    return(
        <>
        <div className='container-fluid-eachProject'>
          <FirstSection 
            projectTitle={Client3.projectTitle}
            year={Client3.year}
            projectType={Client3.projectType}
            clientCompanyName={Client3.companyName}
             projectVideo1={Client3.video1}
             viemoIframe={Client3.vimeo}
            //  projectVideoReel1={Client3.reel1}
             descriptionFirstSection={Client3.disc1}
          />

         <SecondSection 
            secondSectionImag1={Client3.beforeImage}
            secondSectionImag2={Client3.afterImage}
            secondSectionDisc1={Client3.beforetext}
            secondSectionDisc2={Client3.afterText}
         />

         <ThirdSection
            mobileVideo1={Client3.mobileVideo}
            videoTitle={Client3.projectTitle}
            thirdDisc={Client3.disc2}
            button1Name='Visit Client’s Social Media'
            button2Name='Contact Us'
            btn1Link={Client3.clientSocialMedia}
            btn2Link={'/contact'}
             
         />

         <FourthSection
            clientPhoto={Client3.clientPhoto}
            clientName={Client3.clientName}
            clientCompany ={Client3.companyName}
            clientTextReview={Client3.reviewText}
            clientVideoReview={Client3.reviewVideo}
         />
            
        </div>

  <Footer/>
</>
    );
}

export default TscClean;