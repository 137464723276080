import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faMagnifyingGlass, faMicrophoneLines, faHashtag, faClapperboard } from '@fortawesome/free-solid-svg-icons';
import {  faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import './about.css'
import SmallversionAbout from './abouSmVersion'; 
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import { AboutData } from './aboutData';

function SectionsAboutCol(props){
    return(
       <>
            <div className={props.className} data-aos={props.dataAos} data-aos-delay={props.delay} data-aos-duration={props.duration}>
                <span className={props.classNametitle}><FontAwesomeIcon icon={props.icon} size='sm' style={{color: "#002042",}} />   {props.title1}</span>
                <p className={props.classNametext}>{props.text1}</p>
            </div>       
     </>

    );
}

function SectionsAboutCol2(props){
  return(
     <>
          <div className={props.className} data-aos={props.dataAos} data-aos-delay={props.delay} data-aos-duration={props.duration}>
              <span className={props.classNametitle}><FontAwesomeIcon icon={props.icon} size='sm' style={{color: "#002042",}} />   {props.title}</span>
              <p className={props.classNametext}>{props.text}</p>
 
          </div>  
              
   </>

  );
}


function About (){
 
  return(
    <>
      <div className="about-section">
          <div className="container-fluid-about">
          <p className='title-heading'>About Michael D Media</p>
                  <div className="row">
                  <div className='col-4 '>
                  <SectionsAboutCol classNametext="text-about" classNametitle="title-about" className=" row first-about" icon={faMagnifyingGlass} title1="SEARCH ENGINE OPTIMIZATION" text1={AboutData.about1} dataAos="fade-down-right" delay="100"  duration="2000"/>
                  <SectionsAboutCol2 classNametext="text-about" classNametitle="title-about" className="row second-about" icon={faPenToSquare} title="BLOGGING" text={AboutData.about2} dataAos="fade-up-right" delay="100"  duration="2000" />
                  </div>

                  <div className='col-4 imag-about-container'>
                            <img className="img-about" src='images/Michael-D-Mascio-FOR-WEBSITE.png'></img>
                  </div>

                  <div className=' col-4 '>
                  <SectionsAboutCol classNametext="text-about" classNametitle="title-about" className="row third-about" icon={faClapperboard } title1="CONTENT CREATION" text1={AboutData.about3} dataAos="fade-down-left" delay="100"  duration="2000"/>
                  <SectionsAboutCol2 classNametext="text-about" classNametitle="title-about" className="row fourth-about" icon={faMicrophoneLines} title="VOICE OVERS" text={AboutData.about4} dataAos="fade-up-left" delay="100"  duration="2000"/>
                    </div>

                    <div className='row row-fifth-about'>
                    <SectionsAboutCol classNametitle="title-about" classNametext="text-about"  className="col col-lg-6 col-md-6 col-sm-6 fifth-about" icon={faHashtag} title1="SOCIAL MEDIA MANAGEMENT" text1={AboutData.about5} dataAos="fade-up" delay="100"  duration="2000"/>

                    </div>
                  </div>
          </div>
         
      </div>
       <SmallversionAbout/>
      </>
  );
}

export  {About, SectionsAboutCol};