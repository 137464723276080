import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./work.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Button from "../Button/button";
import { Link } from "react-router-dom";
import { Client6, Client1, Client2 } from "../Data/clientsData";

function ItemWork(props) {
  const videoRef = useRef(null);

  // useEffect(() => {
  //   const videoElement = videoRef.current;

  //   const handlePlay = () => {
  //     if (props.sliderRef.current) {
  //       props.sliderRef.current.slickPause();
  //     }
  //   };

  //   const handlePause = () => {
  //     if (props.sliderRef.current) {
  //       props.sliderRef.current.slickPlay();
  //     }
  //   };

  //   videoElement.addEventListener("play", handlePlay);
  //   videoElement.addEventListener("pause", handlePause);

  //   return () => {
  //     videoElement.removeEventListener("play", handlePlay);
  //     videoElement.removeEventListener("pause", handlePause);
  //   };
  // }, [props.sliderRef]);

  return (
    <div className="work-slide">
      <video ref={videoRef} controls>
        <source src={props.src} type="video/mp4" />
      </video>
    </div>
  );
}

const WorkCarousel = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000, //waas 3000
    arrows: true,
    // autoplaySpeed: 5000, //was 1000
    swipeToSlide: true,
  };

  return (
    <div className="work-section-container-fluid">
      <div className="row d-flex align-items-center">
        <div className="col-lg-6 col-md-6 col-sm-12 col-text-work">
          <h2 data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">HAVE A QUICK LOOK AT OUR WORK</h2>
          <h5 data-aos="fade-right" data-aos-delay="170" data-aos-duration="1900">Content By MDM</h5>
         <Link to={'/projects'} ><Button buttonName="See All Projects" className="button-work" dataAos="fade-right" daosDelay="160" aosDuration="1800" /></Link> 
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
         <Slider  {...settings}>    {/* ref={sliderRef} this is was if i will use the useEffect tor slider paused and scroll */}
           
           <ItemWork src= {Client6.reel1}  />
            <ItemWork src={Client2.reel1}  />  
            <ItemWork src={Client1.reel2} />  
            {/* sliderRef={sliderRef}  this was for each ItemWork if i will use the useEffect to scroll after the video paused */}
           
            
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default WorkCarousel;

 