import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './aboutSmVersion.css'
import { SectionsAboutCol } from "./about";
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import {  faMagnifyingGlass, faMicrophoneLines, faHashtag, faClapperboard } from '@fortawesome/free-solid-svg-icons';
import {  faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { AboutData } from './aboutData';

const SmallversionAbout = () => {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      arrows: false,
       
      
    };
  
    return (
        <div className="about-section-sm">
              <p className='titel-heading-about'>About Michael D Media</p>
      <div className="carousel-slider-about">
         <img src="images/Michael-D-Mascio-FOR-WEBSITE.png"></img>
        <Slider {...settings}>
        <SectionsAboutCol className="outer-div-sm-about"  classNametext="text-about" classNametitle="title-about"  title1="SEARCH ENGINE OPTIMIZATION" icon={faMagnifyingGlass} text1={AboutData.about1}/>
        <SectionsAboutCol className="outer-div-sm-about"  classNametext="text-about" classNametitle="title-about"  title1="BLOGGING" icon={faPenToSquare} text1={AboutData.about2}/>
        <SectionsAboutCol className="outer-div-sm-about" classNametext="text-about" classNametitle="title-about" title1="CONTENT CREATION" icon={faClapperboard} text1={AboutData.about3}/>
        <SectionsAboutCol className="outer-div-sm-about" classNametext="text-about" classNametitle="title-about" title1="VOICE OVERS" icon={faMicrophoneLines} text1={AboutData.about4}/>
        <SectionsAboutCol className="outer-div-sm-about" classNametext="text-about" classNametitle="title-about" title1="SOCIAL MEDIA MANAGEMENT" icon={faHashtag} text1={AboutData.about5}/>
        </Slider>
      </div>
        </div>
    );
  };
  
  export default SmallversionAbout;

