import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init()
function Button(props){
   
   const iconStyle = {
         color: props.iconColor
   };

    return(
         <>
            <button className={props.className} data-aos={props.dataAos} data-aos-delay={props.daosDelay} data-aos-duration={props.aosDuration}><FontAwesomeIcon icon={props.icon} style={iconStyle}
             className={props.iconClassName}/> 
             {props.buttonName}</button>
         </>
    );
}

 

export default Button;