import React from 'react';
import { useState } from 'react';
import './experience.css'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger'; 


function Experience() {
    const data = [
      { start: "0", end: "12", content: "Radio Broadcaster",   },
      { start: "0", end: "9", content: "Media Planner",  },
    ];
 
     const [counterOn, setCounterOn] = useState(false);

    return (
        <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=>setCounterOn(false)}> 
      <div className='experience-container'>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-title-ex">
            <h1 data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">Years Of</h1>
            <h1 data-aos="fade-right" data-aos-delay="150" data-aos-duration="1900">Experience</h1>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
          <div className="row row-col-ex" >
            {data.map((item, index) => (
                <div className="col-6  ex-col" key={index}>
                  <h1>{counterOn && <CountUp start={item.start} end={item.end} duration={4} delay={0} />}+</h1> 
                  <p className="">{item.content}</p>
                </div>
            ))}
            </div>
            <div className="row row-col-ex align-items-center d-flex" >
                <div className="col-6  ex-col ex-3"  >
                  <h1 className="">{counterOn && <CountUp start={0} end={6} duration={4} delay={0}/>}+</h1>
                  <p className="">Voice Actor</p>
                </div>
                <div className="col-6  ex-col"  >
                  <img src='/images/cannes-young-lions 1.png' alt="ex-img-lion"></img>
                  <p className="">2016</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      </ScrollTrigger>
    );
  }


  export default Experience;
  