import Footer from "../Footer/footer";
import { FirstSection,ThirdSection, FourthSection , SecondSection} from "./commonProjectPages";
import { Client6} from "../Data/clientsData";

function Silva(){
    return(
        <>
        <div className='container-fluid-eachProject'>
          <FirstSection 
            projectTitle={Client6.projectTitle}
            year={Client6.year}
            projectType={Client6.projectType}
            clientCompanyName={Client6.companyName}
             projectVideo1={Client6.video1}
            viemoIframe={Client6.vimeo}
            //  projectVideoReel1={Client6.reel1}
             descriptionFirstSection={Client6.disc1}
          />

           <SecondSection 
            secondSectionImag1={Client6.beforeImage}
            secondSectionImag2={Client6.afterImage}
            secondSectionDisc1={Client6.beforetext}
            secondSectionDisc2={Client6.afterText}
         />  

         
            
        </div>

  <Footer/>
</>
    );
}

export default Silva;