import './contactForm.css'
import React, { useState, useEffect } from 'react';
import Footer from '../Footer/footer';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import Button from "../Button/button"
import {Buffer} from 'buffer';
function  Contact(){

  const [name, setName] = useState('');
  const [buessniessName, setBuessniessName] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState(''); 
  const [phone, setPhone] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);

  
 
  // console.log("Name" + name)
  // console.log("Notes" + notes)
  // console.log("work name" + buessniessName)
  // console.log("Email" + email)

  
    function handlePhoneNumberChange(value) {
        setPhone(value);
        // console.log(value);
      }

 
 
    
    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      if (checked) {
        setSelectedServices((prevSelectedServices) => [...prevSelectedServices, value]);
      } else {
        setSelectedServices((prevSelectedServices) =>
          prevSelectedServices.filter((service) => service !== value)
        );
      }
    };
  console.log(selectedServices)

 function Services(props){
    return(
        <label className={`checkbox-button ${props.isChecked ? 'checked' : ''}`}>
                <input
                value={props.value}
                    type="checkbox"
                    checked={props.isChecked}
                    onChange={props.onChange}
                    style={{ display: 'none' }}
                />
           {props.text}
            </label>
    );
 }

 const un= process.env.REACT_APP_USERNAME;
 const pd= process.env.REACT_APP_PASSWORD;
//  const db = REACT_APP_SHEETDBLINK
 const encodedCredentials = Buffer.from(`${un}:${pd}`).toString('base64');

   function handleFormSubmit(event){
    event.preventDefault();

   if(email.trim() !== ''){
    fetch(process.env.REACT_APP_SHEETDBLINK, {
      method: 'POST',
      headers: {
        'Accept' : 'application/son',
        'Content-Type': 'application/json',
        Authorization: `Basic ${encodedCredentials}`
      },
      body: JSON.stringify({
        data: [
          {
              SERVICES: selectedServices ,
              NAME: name,
              BUSINESS: buessniessName,
              EMAIL: email,
              PHONE: phone,
              NOTES: notes
          }
        
        ]
      })
    }).then((response) => response.json())
       .then((data)=> {console.log(data);
        window.location.href = '/Booking';
      });
   }
  //  set services
  //  setSelectedServices(false);
  setSelectedServices([]);
   setName('');
   setBuessniessName('');
   setEmail('');
   setPhone('');
   setNotes('');
   }
 
    return(
        <>
         <div className='container-fluid-contactform' data-aos="flip-right">
         <form onSubmit={handleFormSubmit}> 
          <div className='row'>
          <div className='col-lg-7  order-lg-1 order-md-2 order-2'>
              <div className='form-card-container'>
                    <h1>Need Help With Your Project?</h1>
                    <div className='services-container'>
                       <p>Services</p>
                      <Services text='Voice Over' value="Voice Over" isChecked={selectedServices.includes('Voice Over')}
        onChange={handleCheckboxChange}/>
                      <Services text='Content Creation' value="Content Creation" isChecked={selectedServices.includes('Content Creation')}
        onChange={handleCheckboxChange}/>
                      <Services text='Social Media Management' value="Social Media Management" isChecked={selectedServices.includes('Social Media Management')}
        onChange={handleCheckboxChange}  />
                      <Services text='Search Engine Optimization' value="Search Engine Optimization" isChecked={selectedServices.includes('Search Engine Optimization')}
        onChange={handleCheckboxChange} />
                      <Services text='Blogging' value="Blogging" isChecked={selectedServices.includes('Blogging')}
        onChange={handleCheckboxChange}/>
                      </div>
                      
                      <div className='form-input-container'>
                      <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>

                      <div  className="input-container">
                      <input   type='text' id="input" required={true}   onChange={(e)=>setName(e.target.value)} value={name}  />
                      <label htmlFor="input"  className="label">Name</label>
                      <div  className="underline"></div>
                    </div>
                            

                      </div>
                     <div className='col-lg-6 col-md-6 col-sm-12'>
                         
                      <div  className="input-container">
                      <input   type='text' id="Bname" required={true}  onChange={(e)=>setBuessniessName(e.target.value)} value={buessniessName}   />
                      <label htmlFor="Bname"  className="label">Business Name</label>
                      <div  className="underline"></div>
                    </div>
                      </div>
                      </div>

                      <div className='row'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div  className="input-container">
                      <input   type='email' id="email" required={true} onChange={(e)=>setEmail(e.target.value)} value={email}  />
                      <label htmlFor="email"  className="label">Email</label>
                      <div  className="underline"></div>
                    </div>
                      </div>
                     <div className='col-lg-6 col-md-6 col-sm-12'>
                     <PhoneInput className="phoneInput "
                            placeholder="Enter phone number"
                            international
                            defaultCountry="CA"
                            initialValueFormat="national"
                            value={phone}
                            onChange={handlePhoneNumberChange}/>
                      </div>
                           
                      </div>
                      <div  className="input-container-textarea ">
                      <textarea type="text" id="textarea"  required={true} onChange={(e)=>setNotes(e.target.value)} value={notes}   ></textarea>
                    <label htmlFor="textarea"  className="label">Project Details (optional)</label>
                    <div  className="underline"></div>
                        </div>
                            
                               
                      </div>
                   
                      {/* button send */}
                      <Button buttonName="Send" className="button-form"/>
                    </div>
          </div>


          <div className='col-lg-5 order-lg-2 order-md-1 order-1 road-map-container  d-flex align-items-center'>
            <img className='img-roadmap-1' src='images/Timeline.svg'/>
            <img className='img-roadmap-2' src='images/Project Roadmap.svg'/>
          </div>


          </div>
          </form>
         </div>





        <Footer/>

        </>
    );
}

export default Contact;