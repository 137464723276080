import React from 'react';
import Slider from 'react-slick';
import './clientstestimonial.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Client1, Client2, Client3, Client4 } from '../Data/clientsData';
 

const ProjectGeneralTestiomonial = () => {

 function ClientTestimonial(props){
    return(
     <div className='general-clients-container'>
     <div className='row d-flex align-items-center' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
     <div className='col-lg-4 col-md-4 col-sm-12 col-client-img-name '>
        <img src={props.src}/>
        <p>{props.clientName}</p>
        <h6>{props.clientComponey}</h6>
     </div>
     <div className='col-lg-8 col-md-8 col-sm-12 col-text-star'>
     <div className='clinet-text-container'> 
        <p className={props.className}>{props.clientText}</p>
        </div>
        <div className='stars-container'>
           <img src={props.starSrc}></img>
        </div>
     </div>

     </div>
         
     </div>
    );
 }



  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 2000,
    arrows: true,
    // cssEase: 'linear'
  };

  return (
    <div className='container-fluid-clientsTestimonials'>
      <h1 data-aos="fade-right" data-aos-delay="190" data-aos-duration="900">What Clients Say</h1>
      <Slider {...settings}>
        <ClientTestimonial clientComponey={Client1.companyName} className='client-text' starSrc="/images/Group 33.png" clientName={Client1.clientName} clientText={Client1.reviewText} src={Client1.clientPhoto}/>
        
        <ClientTestimonial clientComponey={Client2.companyName} className='client-text' starSrc="/images/Group 33.png" clientName={Client2.clientName} clientText={Client2.reviewText} src={Client2.clientPhoto}/>

        <ClientTestimonial clientComponey={Client3.companyName} className='client-text' starSrc="/images/Group 33.png" clientName={Client3.clientName} clientText={Client3.reviewText} src={Client3.clientPhoto}/>

        <ClientTestimonial clientComponey={Client4.companyName} className='client-text' starSrc="/images/Group 33.png" clientName={Client4.clientName} clientText={Client4.reviewText} src={Client4.clientPhoto}/>


 
      </Slider>
    </div>
  );
};

export default ProjectGeneralTestiomonial;