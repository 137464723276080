import { Fragment } from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import './navigation.css'
 

function Navbar(){
    return(
        <Fragment>
            <nav className="navbar navbar-expand-lg  ">
                <div  className="container-fluid">
                    <Link  to={"/"}><img src="/images/MDM Logo.svg"  className="navbar-brand"></img></Link> 
                    <button  className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span  className="navbar-toggler-icon"></span>
                    </button>
                    <div  className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul  className="navbar-nav me-auto mb-2 mb-lg-0">
                    <NavLink  style={{textDecoration:"none"}}  className="navlink"  activeClassName="active" exact="true" to={"/"}>
                                 <li className="nav-item">
                                  <p className="nav-link ">HOME</p>
                                </li>
                                </NavLink>
                            <NavLink className="navlink" style={{textDecoration:"none"}}  activeclassname="active"    to={"/projects"}>
                                 <li className="nav-item">
                                  <p className="nav-link ">PROJECTS</p>
                                </li>
                                </NavLink>
                                <NavLink    className="navlink" style={{textDecoration:"none"}}    activeclassname="active"  to={"/contact"}>
                                 <li className="nav-item">
                                  <p className="nav-link ">CONTACT US</p>
                                </li>
                                </NavLink>
                                <NavLink    className="navlink" style={{textDecoration:"none"}}    activeclassname="active"  to={"/Booking"}>
                                 <li className="nav-item">
                                  <p className="nav-link ">BOOKING</p>
                                </li>
                                </NavLink>
                              
                      </ul>
                    <div  className="d-flex">
                  <Link to={'/contact'}><button className="nav-button">Work With us</button></Link> 
                    </div>
                </div>
            </div>
         </nav>
         <Outlet/>
        </Fragment>

    );
}

export default Navbar;