import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
import './logoCarousel.css';  

function LogoItem(props) {
  return (
    <div className="logo-container">
      <img className={props.LogoclassName} src={props.src} alt="Logo" />
    </div>
  );
}

const LogoAutoPlay = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed:3000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover:false,
    
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
            arrows: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            dots: false,
            arrows: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
          }
        }
      ]

  };

  return (
    <div className="logoAutoPlay-container">
      <Slider {...settings}>
       
   
        <LogoItem LogoclassName="logo3" src="images/Ford_logo_flat 1.png" />
        <LogoItem LogoclassName="logo1" src="images/American-Express-Color 1.png" />
        <LogoItem LogoclassName="logo6" src="images/Papa_John's_Logo_2019 1.png" />
        <LogoItem LogoclassName="logo8" src="images/Toys__R__Us_logo 1.png" />
        <LogoItem LogoclassName="logo2" src="images/1200px-P&G_logo 1.png" />
        <LogoItem LogoclassName="logo7" src="images/Tolias-Landscaping-2-1080x1080-Transparent 1.png" />
        {/* <LogoItem LogoclassName="logo10" src="images/TownwoodHomes.png" /> */}
        <LogoItem LogoclassName="logo5" src="images/logo 1.png" />
        <LogoItem LogoclassName="logo4" src="images/Group 5.png" />
        <LogoItem LogoclassName="logo9" src="images/TSC-Logo-white 1.png" />
        <LogoItem LogoclassName="logo11" src="images/doseOfSociety.png" />

      </Slider>
    </div>
  );
};

export default LogoAutoPlay;
