import React, { useEffect , useState} from 'react';
import "./App.css"
import { Route, Routes,useLocation } from 'react-router-dom';
import Navbar from './components/navigation/navigationbar';
import Home from './components/Home/home';
import Contact from './components/Contact/contactForm';
import MainProjectsPage from './components/Project/mainProjectpage'
// import ProjectPages from './components/Project/eachProject';
// import SubPage from './components/subPage';
import FantasyGemArt from './components/projectsPages/fantasy';
import Tolias from './components/projectsPages/tolias';
import TscClean from './components/projectsPages/tscClean';
import JDdesign from './components/projectsPages/jdDesign';
import MDMVoiceOvers from './components/projectsPages/voiceOversByMM';
import TidyCalEmbed from './components/Tidycal/Tidycal';
import Preloader from './components/Preloader/preloader';
import Silva from './components/projectsPages/silva';
import DoseOfSociety from './components/projectsPages/dose';
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

 //google analytics
  ReactGA.initialize('G-Y9C2H7MWG7'); 

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};



function App () {


  const [loading, setLoading] = useState(false);

  useEffect(() =>{
     setLoading(true);
     setTimeout(() => {
      setLoading(false);  
      }, 4000);
  },[])




  return (
    <>
    
    {
      loading ?

       <Preloader/>

       :
       <> 
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Navbar/>}>
        <Route index element={<Home/>}/>
        <Route path='contact' element={<Contact/>} />
         <Route path="projects" element={<MainProjectsPage />} />
         <Route path='Booking' element={<TidyCalEmbed/>}/>
         <Route path='projects/FantasyGemArt' element={<FantasyGemArt/>}/>
         <Route path='projects/ToliasLandscaping&Plowing' element={<Tolias/>}/>
         <Route path='projects/TSCWetClean' element={<TscClean/>}/>
         <Route path='projects/JDDesign&Build' element={<JDdesign/>}/>
         <Route path='projects/voiceovers' element={<MDMVoiceOvers/>}/>
         <Route path='projects/SilvaTaping&Drywall' element={<Silva/>}/>
         <Route path='projects/DoseOfSociety' element={<DoseOfSociety/>}/>
        </Route>
      </Routes>
      </>
    }
    
      </>
  );
};
export default App;
