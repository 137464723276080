import Footer from "../Footer/footer";
import { FirstSection,ThirdSection, FourthSection } from "./commonProjectPages";
import { Client2} from "../Data/clientsData";

function Tolias(){
    return(
        <>
        <div className='container-fluid-eachProject'>
          <FirstSection 
            projectTitle={Client2.projectTitle}
            year={Client2.year}
            projectType={Client2.projectType}
            clientCompanyName={Client2.shortCompanyName}
             projectVideo1={Client2.video1}
             viemoIframe={Client2.vimeo}
            //  projectVideoReel1={Client2.reel1}
             descriptionFirstSection={Client2.disc1}
          />

         {/* <SecondSection 
            secondSectionImag1={Client2.beforeImage}
            secondSectionImag2={Client2.afterImage}
            secondSectionDisc1="Before"
            secondSectionDisc2="After"
         /> */}

         <ThirdSection
            mobileVideo1={Client2.mobileVideo}
            videoTitle={Client2.projectTitle}
            thirdDisc={Client2.disc2}
            button1Name='Visit Client’s Social Media'
            button2Name='Contact Us'
            btn1Link={Client2.clientSocialMedia}
            btn2Link={'/contact'}
             
         />

         <FourthSection
            clientPhoto={Client2.clientPhoto}
            clientName={Client2.clientName}
            clientCompany ={Client2.companyName}
            clientTextReview={Client2.reviewText}
            clientVideoReview={Client2.reviewVideo}
         />
            
        </div>

  <Footer/>
</>
    );
}

export default Tolias;