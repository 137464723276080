import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareInstagram, faSquareTwitter,    faSquareWhatsapp, faLinkedin,faTiktok, faSquareYoutube} from "@fortawesome/free-brands-svg-icons"
import { faCircleChevronUp  } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import './footer.css'
function Footer(){
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    return(
    <section className="outer-footer-container">
    {/* <!-- Footer --> */}
    <footer className="footer-up text-center text-white" >
        {/* <!-- Grid container --> */}
        <div className="container p-4 pb-0">
        {/* <!-- Section: CTA --> */}
        <section className="">
        <p className="footer-container">
            <img className="logo-footer" src='/images/MDM Logo.svg' alt="Logo" />
            <span className="icons-container">
            <a href='https://www.youtube.com/@michaeldmedia/featured' target='_blank'><FontAwesomeIcon icon={faSquareYoutube}   size='xl' className='footer-icons' /></a>
            <a href='https://www.instagram.com/michael_d_media/' target='_blank'><FontAwesomeIcon icon={faSquareInstagram}  size='xl' className='footer-icons' /></a>
            <a href='https://www.tiktok.com/@michaeldmedia' target='_blank'><FontAwesomeIcon icon={faTiktok} size='lg' className='footer-icons tiktok-icon' /></a>
                {/* <FontAwesomeIcon icon={faSquareFacebook}   size='xl' className='footer-icons' /> */}
                <a href=" https://www.linkedin.com/in/michael-di-mascio/" target="_blank"><FontAwesomeIcon icon={faLinkedin}   size='xl' className='footer-icons' /></a>
                <a href='https://twitter.com/michael_d_media' target='_blank'><FontAwesomeIcon icon={faSquareTwitter}   size='xl' className='footer-icons' /></a>
                <a href='https://api.whatsapp.com/send?phone=14167356453' target='_blank'><FontAwesomeIcon icon={faSquareWhatsapp}   size='xl' className='footer-icons' /></a>
            </span>
            <Link  to={"/Booking"}><button type="button" className="btn-footer   btn-rounded">
                Book A Meeting
            </button></Link> 
            <FontAwesomeIcon icon={faCircleChevronUp}    size='2xl' className='backTop' onClick={scrollToTop} />
        </p>
        </section>
    
        </div>
      
        <div className="footer-bottom  p-3 copy-right">
        © 2023 Michael D Media
        {/* <a className="text-white" href="https://mdbootstrap.com/">MDBootstrap.com</a> */}
        </div>
         
    </footer>
  
    </section>

    );
}

export default Footer;

