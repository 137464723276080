export const Client1 = {
    clientName: "Mike De Gennaro",
    companyName: "Fantasy Gem Art",
    reviewText: "As a small business owner, we've been working with Michael the Media, for a couple of years now. He's become an extension of our company, fits right in and gives us 100% of his time. We collaborate on ideas together. He's very open to my suggestions and if something doesn't work, we put our minds back together. We recalculate and it's very comforting and easy to work with Michael being there. We're extremely happy and looking forward to several more years together.",
    clientPhoto:"/images/client1Photo.png",
    reviewVideo:'/videos/fgReview.mp4',

    // projects  data for each client
    vimeo:"https://player.vimeo.com/video/854054609?h=1b1710b69a",
    CardImage:"/images/FG Project Cover.jpg",
    clientSocialMedia:'https://www.instagram.com/fantasy.gemart/',
    projectTitle:"Fantasy Gem Art Project",
    year:'2020-Present',
    projectType: 'Media Production',
    video1:'/videos/FG Project Square Video.mp4',
    reel1:'/videos/FG 3.2 - Customer Appreciation with Music.mp4',
    reel2: '/videos/3.6 FG Wedding Season Final.mp4',
    beforeImage:'/images/FG Before.jpg',
    afterImage:'/images/FGafter.jpg',
    beforetext:'Before',
    afterText:'After',
    disc1:"Elevating Fantasy Gem Art's online presence continues to be a rewarding journey. As a leader in Custom Jewelry with over 60 Years in Business, our ongoing collaboration involves Producing and Creating Videos that showcase their Exquisite Creations. The success of our Social Media Strategy and targeted Ad Campaigns has led to Increased Engagement and a Higher Return on Investment. Our unwavering commitment to Search Engine Marketing ensures consistent visibility in search results, further solidifying their online prominence.",
    disc2:"Embarking on Fantasy Gem Art's digital journey has been a source of great pride. Boasting 60+ Years of Crafting Excellence in Custom Jewelry, our collaboration revolved around crafting mesmerizing videos to present their Exquisite Creations. Our Tailored Social Media Strategy and Precision-Targeted Ad Campaigns resulted in Amplified Engagement and a Resounding ROI, cementing their online eminence.",
    mobileVideo:'/videos/mobile-FG.mp4',


};

export const Client2 = {
    clientName: "Peter Tolias",
    companyName: "Tolias Landscaping & Plowing",
    shortCompanyName: "Tolias L&P",
    reviewText: "Want to give a big shout out to my good friend Michael D Media, Putting out some great content for Tolias Landscaping & Plowing and taking my Instagram to another level. I really appreciate all his hard work and we wouldn't be able to be where we are without it.",
    clientPhoto:"/images/client2Photo.png",
    reviewVideo: "/videos/Peter Tolias Testimonial.mp4",

    // projects  data for each client
    CardImage:"/images/Tolias Project Cover.jpg",
    vimeo:"https://player.vimeo.com/video/854028213?h=73bf9e6acb",
    clientSocialMedia:'https://www.instagram.com/tolias_landscaping/',
    projectTitle:"Tolias Landscaping & Plowing",
    year:'2022-2023',
    projectType: 'Media Production',
    video1:'/videos/Tolias Project Square Video.mp4',
    reel1:'/videos/Tolias LP A2 Reel 2 v3.mp4',
    beforeImage:'',
    afterImage:'',
    beforetext:'',
    afterText:'',
    disc1:"Clearing the way for Tolias Landscaping and Plowing's growth was an honor. With 23+ years of snow-free expertise and serving renowned names like Lowe's, The Daniels Corp., and more, we produced engaging videos that captured their dedication. Through strategic social media consultations, we enhanced their online strategy, connecting them with a wider audience and reinforcing their reputation as industry leaders.",
    disc2:"Nurturing Tolias Landscapin and Plowing's digital footprint has been a privilege. Our captivating videos have spotlighted their 23+ years of snow-clearing mastery. Armed with strategic social media insights, we've broadened their online grasp, forging connections with a wider audience and reaffirming their stature as trailblazers in the industry.",
    mobileVideo:'/videos/mobile-Tolias.mp4',

};

export const Client3 = {
    clientName: "Dino Kantzavalos",
    companyName: "TSC Wet Clean",
    reviewText: "We've been working with MDM for the last three years. We really appreciate the content and the effort put into all the great work that they produce for us. They've been a great help for our business.",
    clientPhoto:"/images/Dino Kantzavalos Picture Circle.png",
    reviewVideo: "/videos/TSC Testimonial New Square.mp4",

    // projects  data for each client
    CardImage:"/images/TSC Project Cover.jpg",
    vimeo:"https://player.vimeo.com/video/854057495?h=cfeae7e5b1",
    clientSocialMedia:'https://www.instagram.com/tscwetclean/',
    projectTitle:"TSC WETCLEAN",
    year:'2020-2023',
    projectType: 'Media Production',
    video1:'/videos/TSC Project Square Video.mp4',
    reel1:'/videos/TSC Wet Clean - We Clean Everything_2_2.mp4',
    beforeImage:'/images/tscBefore.jpg',
    afterImage:'/images/tscAfter.jpg',
    beforetext:'Before',
    afterText:'After',
    disc1:"In a partnership with TSC WetClean, an environmentally-friendly wet cleaner, we played a pivotal role in their journey towards sustainable success. TSC WetClean's exceptional services have reached prominent stages, including collaborations with celebrities, baseball teams, and Hollywood movie sets. Our collaboration involved producing and editing videos that highlighted these remarkable affiliations. By strategically consulting on their social media strategy and executing impactful ad campaigns, we elevated TSC WetClean's online reach and contributed to achieving an optimal return on investment. Our efforts have significantly boosted their credibility, reinforcing their reputation as a trusted industry player.",
    disc2:"Our synergy with TSC WetClean, an eco-conscious wet cleaner, has been a catalyst for bolstering their acclaim. We've showcased their partnerships with luminaries, baseball teams, and Hollywood sets, underscoring their credibility. Through shrewd social media consultation and potent ad campaigns, we've propelled their reach and ROI, fueling their sustainable ascendancy.",
    mobileVideo:'/videos/mobile-TSC.mp4',
};

export const Client4 = {
    clientName: "Joseph Didiano",
    companyName: "JD Design & Build",
    reviewText: "Michael D. Media has been the voice of our company for the past 2 years, we appreciate all the hard work.",
    clientPhoto:"/images/clientJD.png",
    reviewVideo: "/videos/jdReview.mp4",

    // projects  data for each client
    CardImage:"/images/JD Project Cover.jpg",
    vimeo:"https://player.vimeo.com/video/856626587?h=2ae6fce6eb&title=0&portrait=0",
    projectTitle:"JD Design & Build",
    year:'2023',
    projectType: 'Media Production & Voice Over',
    video1:'/videos/JD Design SQ Vid MDM Website.mp4',
    reel1:'',
    beforeImage:'',
    afterImage:'',
    beforetext:'',
    afterText:'',
    disc1:'',
    disc2:'',
    mobileVideo:'',
};

export const Client5 = {
    clientName: "",
    companyName: "",
    reviewText: "",
    clientPhoto:"",
    reviewVideo: "",

    // projects  data for each client
    CardImage:"/images/VoiceOvers Project Cover.jpg",
    projectTitle:"MDM Voice overs",
    year:'',
    projectType: '',
    video1:'/videos/Michael-Papa-John_s-preroll (2).mp4',
    voiceOver1: '/videos/Michael D Media - VO Showreel.mp4',
    voiceOver2: '/videos/Sherwin Williams VO.mp4',
    reel1:'',
    beforeImage:'',
    afterImage:'',
    beforetext:'',
    afterText:'',
    disc1:'',
    disc2:'',
    mobileVideo:'',
};

export const Client6 = {
    clientName: "",
    companyName: "Silva Taping & Drywall",
    reviewText: "",
    clientPhoto:"",
    reviewVideo: "",

    // projects  data for each client
    CardImage:"images/Silva T&D Project Cover.png",
    projectTitle:"Silva Taping & Drywall",
    year:'2023',
    projectType: 'Social Media Management',
    video1:'/videos/Silva Square Design 2.mp4',
    reel1:'/videos/3.5 Silva.mp4',
    vimeo:"https://player.vimeo.com/video/854055847?h=4c580a6d44",
    beforeImage:'/images/silvaBefore.png',
    afterImage:'/images/silvaAfter.png',
    beforetext:'Before',
    afterText:'30 Days Later',
    disc1:'',
    disc2:'',
    mobileVideo:'',
};
 

export const ClientDose = {
    clientName: "Dose Of Society",
    companyName: "Dose Of Society",
    reviewText: "",
    clientPhoto:"",
    reviewVideo: "",

    // projects  data for each client
    CardImage:"images/Dose Project Cover.png",
    projectTitle:"Dose Of Society",
    year:'2021',
    projectType: 'Voice Overs',
    video1:'/images/DoseSquare.png',
    reel1:'',
    vimeo:"https://player.vimeo.com/video/856630069?h=1de7fcd223&title=0&portrait=0",
    beforeImage:'',
    afterImage:'',
    beforetext:'',
    afterText:'',
    disc1:'',
    disc2:'',
    mobileVideo:'',
};

