import './preloader.css'

function Preloader(){
    return(
        
        <div className='bg-preloader'> 
        <div class="loader">
        <div class="bar1">M</div>
        <div class="bar2">i</div>
        <div class="bar3">c</div>
        <div class="bar4">h</div>
        <div class="bar5">a</div>
        <div class="bar6">e</div>
        <div class="bar7">l</div>
        <div class="bar8">D</div>
        <div class="bar9">M</div>
        <div class="bar10">e</div>
        <div class="bar11">d</div>
        <div class="bar12">i</div>
        <div class="bar13">a</div>
    </div>
    </div>
    );
}

export default Preloader;