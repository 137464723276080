import Footer from "../Footer/footer";
import { FirstSection } from "./commonProjectPages";
import { ClientDose } from "../Data/clientsData";

function doseOfSociety(){
    return(
        <>
        <div className='container-fluid-eachProject'>
          <FirstSection 
            projectTitle={ClientDose.projectTitle}
            year={ClientDose.year}
            projectType={ClientDose.projectType}
            clientCompanyName={ClientDose.companyName}
             projectVideo1={ClientDose.video1}
             viemoIframe={ClientDose.vimeo}
            //  descriptionFirstSection={ClientDose.disc1}
          />
    </div>

<Footer/>
</>
    );
}

export default doseOfSociety;