import Footer from "../Footer/footer";
import { FirstSection, SecondSection,ThirdSection, FourthSection } from "./commonProjectPages";
import { Client1 } from "../Data/clientsData";
 
function FantasyGemArt(){
    return(
        <>
        <div className='container-fluid-eachProject'>
          <FirstSection 
            projectTitle={Client1.projectTitle}
            year={Client1.year}
            projectType={Client1.projectType}
            clientCompanyName={Client1.companyName}
             projectVideo1={Client1.video1}
            //  projectVideoReel1={Client1.reel1}
            viemoIframe={Client1.vimeo}
             descriptionFirstSection={Client1.disc1}
          />

         <SecondSection 
            secondSectionImag1={Client1.beforeImage}
            secondSectionImag2={Client1.afterImage}
            secondSectionDisc1={Client1.beforetext}
            secondSectionDisc2={Client1.afterText}
         />

         <ThirdSection
            mobileVideo1={Client1.mobileVideo}
            videoTitle={Client1.projectTitle}
            thirdDisc={Client1.disc2}
            button1Name='Visit Client’s Social Media'
            button2Name='Contact Us'
            btn1Link={Client1.clientSocialMedia}
            btn2Link={'/contact'}
             
         />

         <FourthSection
            clientPhoto={Client1.clientPhoto}
            clientName={Client1.clientName}
            clientCompany ={Client1.companyName}
            clientTextReview={Client1.reviewText}
            clientVideoReview={Client1.reviewVideo}
         />
            
        </div>

  <Footer/>
</>
    );
}

export default FantasyGemArt;