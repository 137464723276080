import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer/footer'
import './mainProjectpage.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
 import ProjectGeneralTestiomonial from './clientsTestimonial'
import { Client1,Client2,Client3,Client4,Client5, Client6, ClientDose } from '../Data/clientsData';
// import ReactGA from 'react-ga';
// import ReactGA from "react-ga4";


// ReactGA.initialize(`${process.env.REACT_APP_ANALYTICID}`);

  //google analytics useEffect
//   useEffect(()=>{
//     ReactGA.pageview(window.location.pathname);
// },[])

const MainProjectsPage = () => {


 
function ProjectCard(props) {

  return (
    <Link to={props.to}>
      <div className="slide-box" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
        <img src={props.CardImageSrc} alt="Slide 1" />
        
        <div class="overlay"></div>
        <button class="btn-showMore"><p>{props.btnName}</p></button>
      </div>
    </Link>
  );
}

  const CenterMode = () => {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      dots: false,
      arrows:true,
      autoplay: false,
      speed: 2000, //500
      autoplaySpeed: 5000,
     
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint:830,
          settings: {
           arrows:false,
           slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            centerMode: false,
          }
        },
        {
          breakpoint:990,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            centerMode: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            centerMode: false,
            arrows:false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            arrows:false,
          }
        }
      ]
    };
  
    return (
      <div className="outer-projects">
      <h1 data-aos="fade-right" data-aos-delay="190" data-aos-duration="900">Projects By <br/><span className='bg-blue-michael'>Michael</span>D Media</h1>
      <Slider {...settings}>
        <ProjectCard CardImageSrc={Client1.CardImage} to="/projects/FantasyGemArt" btnName="SHOW MORE"  />
        <ProjectCard CardImageSrc={Client2.CardImage} to="/projects/ToliasLandscaping&Plowing" btnName="SHOW MORE"  />
        <ProjectCard CardImageSrc={Client6.CardImage} to="/projects/SilvaTaping&Drywall" btnName="SHOW MORE" />
        <ProjectCard CardImageSrc={Client5.CardImage} to="/projects/voiceovers" btnName="SHOW MORE" />
        <ProjectCard CardImageSrc={ClientDose.CardImage} to="/projects/DoseOfSociety" btnName="SHOW MORE"  />
        <ProjectCard CardImageSrc={Client4.CardImage} to="/projects/JDDesign&Build" btnName="SHOW MORE" /> 
        <ProjectCard CardImageSrc={Client3.CardImage} to="/projects/TSCWetClean" btnName="SHOW MORE"  />

        
      </Slider>
    </div>
    );
  };
  



  return (
    <> 
   
    <div className='projects-container'>
         <CenterMode />
    </div>
       
       <div className='clientsTestimonial-container'>
       <ProjectGeneralTestiomonial/>
       </div>       
 <Footer/>
    
    </>
  );
};

export default MainProjectsPage;
