import Footer from "../Footer/footer";
// import { FirstSection, SecondSection,ThirdSection, FourthSection } from "./commonProjectPages";
import { Client5 } from "../Data/clientsData";


function MDMVoiceOvers(){
    return(
        <> 
      <div className="container-fluid-eachProject-mdmVoiceovers">
      <h1 className='eachproject-mainTitle'>{Client5.projectTitle}</h1>
        <div className="pizzavid-container">
            <video className="pizzaAD-projects" controls>
                <source src={Client5.video1}></source>
            </video>
            <p>{Client5.disc1}</p>
        </div>

        <div className="row row-voiceovers">
        <div className="col-6">
        <video className="voiceOvers1-projects" controls>
                <source src={Client5.voiceOver1}></source>
            </video>
        </div>
        <div className="col-6">
        <video className="voiceOvers2-projects" controls>
                <source src={Client5.voiceOver2}></source>
            </video>
        </div>

        </div>
        </div>
        <Footer/>
        </>
    );
}

export default MDMVoiceOvers;